@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .average-translate {
    @apply -translate-y-1;
  }
  .slower-transition {
    transition: ease-in-out 0.2s;
  }

  .average-transition {
    transition: ease-in-out 0.15s;
  }

  .dimmed-0 {
    /* blog dates */
    color: hsl(0, 0%, 48%);
  }

  .dimmed-1 {
    /* blog after 'x' minutes */
    color: hsl(0, 0%, 65%);
  }

  .dimmed-3 {
    /* content reading */
    color: hsl(0, 0%, 75%);
  }

  .dimmed-4 {
    /*H2s  */
    color: hsl(0, 0%, 90%);
  }

  .dimmed-5 {
    /* H1s */
    color: hsl(0, 0%, 95%);
  }
  .glowsup:hover {
    /* Major text I */
    filter: drop-shadow(0 0 0.75rem var(--deeper-purple));
  }
  .glows {
    filter: drop-shadow(0 0 0.75rem var(--deeper-purple));
  }
  .glows-dimmed {
    filter: drop-shadow(0 0 0.75rem var(--deeper-purple-dimmed));
  }
  .glowsup-dimmed:hover {
    /* Major text II */
    filter: drop-shadow(0 0 0.75rem var(--deeper-purple-dimmed));
  }

  .turn-bg-gradient::after {
    content: "";
    position: fixed;
    left: -1px;
    top: -1px;
    z-index: -1;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    opacity: 0;
    transition: opacity 0.5s;
  }

  .turn-bg-gradient::after {
    /*If I change my mind about the theme I'll use this for individual components*/
    background-image: linear-gradient(
      84.16deg,
      rgba(150, 46, 200, 0.15),
      rgba(255, 215, 111, 0.042),
      rgba(30, 17, 224, 0.1)
    );
  }
  /* 
  .turn-bg-gradient:hover::after {
    opacity: 1;
  } */

  /* TODO: actualy rename this on a second though, it's not only used in nav */
  .nav-link-shadow {
    position: relative;
  }

  @media (min-width: 1024px) {
    .nav-link-shadow::before {
      content: "";
      position: absolute;
      height: 33px;
      width: 70px;
      z-index: -1;
      background: var(--deeper-purple);
      filter: blur(25px);
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    .nav-link-shadow:hover::before {
      opacity: 0.8;
    }
  }

  .gradient-text-purple {
    /*
         for links
      */
    @apply bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent;
    -webkit-background-clip: text; /* older browsers can get this too */
  }

  @keyframes nav-link-shadow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
}

.highlight-underline {
  position: relative;
}

.highlight-underline::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  transition: width 0.1s ease;
}

.highlight-underline:hover::after {
  width: 100%;
}
