.ham-line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition:
    stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.ham-line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.ham-line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.ham-line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.ham-opened .ham-line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.ham-opened .ham-line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.ham-opened .ham-line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
